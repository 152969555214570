import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import ReactVivus from "react-vivus"
import Vivus from "vivus"
import { Link } from "gatsby"

import priceListStyles from "src/css/price_list.module.css"


const PriceItem = ({ title, desc, price, prefix }) => (
  <div className={priceListStyles.price_item}>
    <div className={priceListStyles.text}>
      <div className={priceListStyles.price_title}>{title}</div>
      <div className={priceListStyles.desc}>{desc}</div>
    </div>
    <div className={priceListStyles.price}><span>{prefix}</span> {price}</div>
  </div>
)


class SuggestionItem extends React.Component {
  constructor(props) {
    super(props)

    this.id = "suggestionIcon" + Math.floor((Math.random() * 1000000) + 1)

    this.state = {
      id: "suggestionIcon" + Math.floor((Math.random() * 1000000) + 1)
    }
  }

  componentDidMount() {
    // new Vivus(this.id, {
    //   file: this.props.item.icon,
    //   type: "delayed",
    //   animTimingFunction: Vivus.EASE,
    //   duration: 100,
    //   start: "autostart",
    // }, () => {
    //   console.log("sug icon done")
    // })
  }

  render() {
    return (
      <div className={priceListStyles.suggestion_item}>
        <div className={priceListStyles.image}>
          {/*<img src={this.props.item.icon} />*/}
          <div id={this.id} />
        </div>
        <div className={priceListStyles.text}>
          <div className={priceListStyles.suggestion_title}>{this.props.item.title.pl}</div>
          <div className={priceListStyles.button}><Link to={this.props.item.link}>PRZEJDŹ</Link></div>
        </div>
      </div>
    )
  }
}


export default class PriceList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={priceListStyles.block} id={this.props.id}>
        <div className={priceListStyles.title}>Cennik</div>
        <div className={priceListStyles.content}>
          <div className={priceListStyles.price_table}>
            {this.props.content.items.map((item, index) => (
              <PriceItem title={item.title} desc={item.desc} price={item.price} prefix={item.prefix} key={index}/>
            ))}
          </div>
          {/*<div className={priceListStyles.suggestions}>*/}
          {/*  <div className={priceListStyles.suggestion_items}>*/}
          {/*    {this.props.content.suggestions.map((item, index) => (*/}
          {/*      <SuggestionItem item={item} key={index}/>*/}
          {/*    ))}*/}
          {/*  </div>*/}
          {/*</div>*/}
          <div className={priceListStyles.law_info}>
            <div className={priceListStyles.item}>
              Cennik dla celów informacyjnych/poglądowych – każdorazowo zlecenie
              podlega osobnej wycenie.
            </div>
            <div className={priceListStyles.item}>
              Wszystkie ceny usług są cenami NETTO, do których należy doliczyć podatek VAT według obowiązujących stawek.
            </div>
          </div>
        </div>
      </div>
    )
  }
}


PriceList.defaultProps = {
  content: {
    items: [
      { title: "title", desc: "desc", price: "500 zł", prefix: "od" },
    ],
    suggestions: []
  },
}

PriceList.propTypes = {
  content: PropTypes.object,
  id: PropTypes.string
}


//
// export default connect(state => ({
//   scroll: state.scroll,
// }), null)(PriceList)