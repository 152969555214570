import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link as ScrollLink } from "react-scroll"
import { SlideDown } from "react-slidedown"

import { changeNaviW } from "src/store/reducers/sizes"

import styles from "src/css/subpage.module.css"
import "react-slidedown/lib/slidedown.css"

import nav_icon from "src/images/nav_icon.svg"
import nav_icon_black from "src/images/nav_icon_black.svg"


class SubpageFirstScreen extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      openNavi: false,
    }

    this.naviObj = React.createRef()

    this.icon = this.props.black ? nav_icon_black : nav_icon

    this.changeNaviState = this.changeNaviState.bind(this)
  }

  resizeEvent() {
    if (this.naviObj.current !== null) {
      this.props.dispatch(changeNaviW(this.naviObj.current.clientWidth))
    }
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeEvent.bind(this))
    window.addEventListener("load", this.resizeEvent.bind(this))
    this.resizeEvent()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.resizeEvent.bind(this))
    window.removeEventListener("load", this.resizeEvent.bind(this))
  }

  changeNaviState() {
    this.setState({ ...this.state, openNavi: !this.state.openNavi })
  }

  render() {
    return (
      <div className={[styles.subpage_screen, styles.subpage_first_screen].join(" ")}
           style={this.props.style}>
        <div className={styles.subpage_first_screen_layout}>
          {this.props.isMobile.isMobile ? (
            <div className={styles.mobile_subpage_navi_wrapper}>
              <div className={styles.mobile_subpage_navi_header}>
                <div className={styles.mobile_subpage_navi_title}>{this.props.content.title}</div>
                <div className={styles.mobile_subpage_navi_button} onClick={this.changeNaviState}>
                  <img src={nav_icon}/>
                </div>
              </div>
              <SlideDown>
                {this.state.openNavi ? (
                  <div className={styles.mobile_subpage_navi}>
                    {this.props.content.sitemap.map((item, index) => (
                      <ScrollLink to={item.id} smooth={true} duration={500} key={index}>
                        <div className={styles.mobile_subpage_navi_item}>{item.title}</div>
                      </ScrollLink>
                    ))}
                  </div>
                ) : null}
              </SlideDown>
            </div>
          ) : (
            <div className={styles.subpage_page_navi} ref={this.naviObj}>
              <div className={styles.subpage_title}>{this.props.content.title}</div>
              {this.props.content.sitemap.map((item, index) => (
                <ScrollLink to={item.id} smooth={true} duration={500} key={index}>
                  <div className={styles.navi_item}>{item.title}</div>
                </ScrollLink>
              ))}
            </div>
          )}
          <div className={styles.screen_1st_content_wrapper}>
            {this.props.empty ? null : (
              <div className={styles.screen_1st_content} style={{
                width: this.props.isMobile.isMobile ? "100%" : "calc(100vw - 300px)",
              }}>
                <div className={styles.screen_1st_content_box}>
                  <div className={styles.subpage_slogan}>{this.props.content.slogan}</div>
                  <div className={styles.subpage_first_screen_content_layout}>
                    <div className={styles.subpage_image}>
                      <img src={this.props.content.img}/>
                    </div>
                    <div className={styles.subpage_first_screen_space}/>
                    <div className={styles.subpage_first_screen_text}>
                      {Object.keys(this.props.content.texts).map(key => {
                        let text = this.props.content.texts[key]
                        return (
                          <div className={styles.text_block} key={key}>
                            <div className={styles.subtitle}>{text.title}</div>
                            <div className={styles.text}>{text.desc}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {this.props.children}
          </div>
        </div>
      </div>
    )
  }
}

SubpageFirstScreen.defaultTypes = {
  content: {
    title: "TITLE",
    sitemap: [
      { title: "title", id: "id" },
      { title: "title", id: "id" },
      { title: "title", id: "id" },
      { title: "title", id: "id" },
    ],
    slogan: "Slogan",
    img: "link",
    texts: {
      1: {
        title: "title",
        desc: "desc",
      },
      2: {
        title: "title",
        desc: "desc",
      },
    },
  },
  style: {},
  empty: false,
}

SubpageFirstScreen.propTypes = {
  content: PropTypes.object,
  empty: PropTypes.bool,
  style: PropTypes.object,
  black: PropTypes.bool
}

SubpageFirstScreen.defaultProps = {
  black: false
}

export default connect(state => ({
  menuH: state.menuH,
  naviW: state.naviW,
  isMobile: state.isMobile,
}), null)(SubpageFirstScreen)