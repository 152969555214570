import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import styles from "src/css/subpage.module.css"


export default class SubpageLayout extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={styles.subpage_layout}>
        {this.props.children}
      </div>
    )
  }
}

SubpageLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

// export default connect(state => ({
//   scroll: state.scroll,
// }), null)(SubpageLayout)