import React from "react"

import SEO from "src/components/seo"
import Layout from "src/components/layout"
import GradientsNew from "src/components/gradients-new"
import TopMenu from "src/components/top_menu"
import SubpageLayout from "src/components/subpage_layout"
import SubpageFirstScreen from "src/components/subpage_first_screen"
import PriceList from "src/components/prices_list"
import Footer from "src/components/footer"
import MobileMenu from "src/components/mobile_menu"

import divisions from "src/content/divisions"

import subpageStyles from "src/css/subpage.module.css"

import kapanie from "src/images/pages/marketing/marketing.png"
import { connect } from "react-redux"
import rlt_marketing from "src/images/pages/marketing/marketing-elo.jpg"

const Marketing = ({isMobile}) => (
  <Layout>
    <SEO title="Kampanie reklamowe - Departament Reklamy" keywords={[`departament reklamy`, `elo`, `react`]} lang="pl"/>
    <GradientsNew activeGradient={3} iconBg={divisions.marketing.icon}/>
    {isMobile.isMobile ? (
      <MobileMenu homeBack={true}/>
    ) : (
      <TopMenu homeBack={true}/>
    )}

    <SubpageLayout>
      <SubpageFirstScreen content={{
        title: "KAMPANIE REKLAMOWE",
        sitemap: [
          { title: "Opis", id: "id" },
          { title: "Real Time Marketing", id: "rtm" },
          { title: "Cennik", id: "cennik" },
        ],
        slogan: "Nasze kroki, twój sukces",
        img: kapanie,
        texts: {
          1: {
            title: "KAŻDY KROK NA SZCZYT ZROBIMY RAZEM Z TOBĄ",
            desc: "Kreatywność, wytrwałość w działaniu i profesjonalizm, to cechy dzięki którym osiągamy satysfakcjonującą " +
            "współpracę z klientem na każdej płaszczyźnie działalności.",
          },
          2: {
            title: "TWÓJ PRODUKT, TWOJA SPOŁECZNOŚĆ",
            desc: "Zbuduj grnono stałych odbiorców twojego produktu. Pomożemy ci ustalić grupę docelową oraz metody na jej zdobycie.",
          },
        },
      }}/>


      <div className={[subpageStyles.subpage_block, subpageStyles.white, subpageStyles.halfimg_block].join(" ")} id="rtm">
        <div className={subpageStyles.image} style={{
          backgroundImage: "url(" + rlt_marketing + ")",
          backgroundPosition: "top right",
          backgroundSize: "auto 100%",
        }}/>
        <div className={subpageStyles.content}>
          <div className={subpageStyles.title}>Real Time Marketing</div>
          <div className={subpageStyles.subtitle}>Znamy się na trendach</div>
          <div className={subpageStyles.text}>
            Real Time Marketing to najlepsza droga by Twoja marka zdobyła popularność. Dzięki szybkiej reakcji na aktualne trendy
            w postaci tworzenia tematycznych promocji możesz zdobyć większe zasięgi twoich wpisów na portalach społecznościowych.
          </div>
        </div>
      </div>


      <div className={[subpageStyles.subpage_block].join(" ")} style={{
        height: "20vh",
      }}/>



      <PriceList id="cennik" content={{
        items: [
          { title: "Identyfikacja wizualna", desc: "Logo, logotyp", price: "500 zł", prefix: "od" },
          { title: "Stworzenie marki", desc: "Logo, logotyp", price: "500 zł", prefix: "od" },
          { title: "Identyfikacja wizualna", desc: "Logo, logotyp", price: "500 zł", prefix: "od" },
          { title: "Identyfikacja wizualna", desc: "Logo, logotyp", price: "500 zł", prefix: "od" },
        ],
        suggestions: [divisions.papers],
      }}/>
      {isMobile.isMobile ? null : (
        <Footer/>
      )}
    </SubpageLayout>
  </Layout>
)

export default connect(state => ({
  isMobile: state.isMobile,
}), null)(Marketing)